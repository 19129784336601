<template>
  <div class="social-icons">
    <a href="#" class="facebook"><i class="fab fa-facebook-f"></i></a>
    <a href="#" class="twitter"><i class="fab fa-twitter"></i></a>
    <a href="#" class="instagram"><i class="fab fa-instagram"></i></a>
    <a href="#" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
    <a href="#" class="youtube"><i class="fab fa-youtube"></i></a>
    <a href="#" class="whatsapp"><i class="fab fa-whatsapp"></i></a>
  </div>
</template>

<style scoped>
.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons a {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05); /* Default background color */
  transition: background-color 0.3s ease;
}

.social-icons a i {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6); /* Default icon color */
  transition: color 0.3s ease;
}

.social-icons a:hover {
  background-color: #ffffff;
}

.social-icons a.facebook:hover {
  background-color: #3b5998;
  color: white;
}

.social-icons a.twitter:hover {
  background-color: #00acee;
}

.social-icons a.instagram:hover {
  background-color: #e4405f;
}

.social-icons a.linkedin:hover {
  background-color: #0077b5;
}

.social-icons a.youtube:hover {
  background-color: #ff0000;
}

.social-icons a.whatsapp:hover {
  background-color: #25d366;
}
</style>
