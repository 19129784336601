<template>
  <div class="card">
    <div class="icon" v-if="iconType === 'email'">
      <i class="fa-solid fa-at" aria-hidden="true"></i>
    </div>
    <div class="icon" v-else-if="iconType === 'address'">
      <i class="fa-solid fa-map-location-dot" aria-hidden="true"></i>
    </div>
    <div class="icon" v-else-if="iconType === 'whatsapp'">
      <i class="fa-brands fa-whatsapp" aria-hidden="true"></i>
    </div>
    <div class="icon" v-else-if="iconType === 'phone'">
      <i class="fa-solid fa-phone" aria-hidden="true"></i>
      <!-- Default Icon -->
    </div>
    <div class="content">
      <h2>{{ headerText }}</h2>
      <p v-html="subText"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconCard", // Renamed for better accuracy
  props: {
    headerText: {
      type: String,
      required: true,
    },
    subText: {
      type: String,
      required: true,
    },
    iconType: {
      type: String,
      required: true,
      validator: (value) => ["email", "address", "whatsapp"].includes(value),
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  max-width: 100%;
  width: 100%;
}

.icon {
  background-color: #f0f0f0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.icon i {
  color: #6b7280;
  font-size: 24px; /* Adjust as needed */
}

.content {
  flex: 1;
}

h2 {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin: 0 0 8px 0;
  text-align: center;
}

p {
  font-size: 14px;
  color: #6b7280;
  margin: 0;
  line-height: 1.4;
  text-align: center;
}

@media (max-width: 480px) {
  .card {
    flex-direction: column;
    align-items: flex-start;
  }

  .icon {
    margin-right: 0;
    margin-bottom: 12px;
  }
}
</style>
