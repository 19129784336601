<template>
  <div class="container">
    <div class="column">
      <h2>Our Mission</h2>
      <p>
        Our mission is to provide world-class dental care with a focus on
        patient comfort, quality, and affordability. We are dedicated to
        offering personalized treatments that cater to each patient’s unique
        needs, ensuring exceptional results. Whether it’s dental care or select
        aesthetic services, we are committed to making every patient’s journey
        with us smooth, satisfying, and transformative.
      </p>
    </div>
    <div class="column">
      <h2>Our Vision</h2>
      <p>
        At Health Medical Center, we envision becoming a premier destination for
        comprehensive dental and aesthetic care. We aim to deliver exceptional
        treatments that not only improve health but also boost confidence and
        well-being. By combining innovation, expertise, and a personalized
        approach, we strive to create beautiful, lasting smiles and experiences
        that leave a lasting impact on our patients from around the world.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionVision",
};
</script>

<style scoped>
.container {
  display: flex;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 15px;
}

.column {
  flex: 1;
  padding: 20px;
}
.column h2 {
  font-weight: 600;
}

.column:first-child {
  border-right: 1px solid #e0e0e0;
}

h2 {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid #c49754;
}

p {
  color: #34495e;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .column:first-child {
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }
}
</style>
