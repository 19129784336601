<!-- layouts/LayoutHome.vue -->
<template>
  <div id="layout-container">
    <!-- Header Section -->
    <header>
      <nav-bar-child></nav-bar-child>
      <nav-bar></nav-bar>
    </header>

    <!-- Main Content -->
    <main>
      <slot></slot>
    </main>

    <!-- Footer Section -->
    <footer>
      <footer-component></footer-component>
    </footer>
  </div>
</template>

<script>
import NavBarChild from "@/components/NavBarChild.vue";
import NavBar from "@/components/NavBar.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "LayoutHomeVue",
  components: {
    NavBar,
    NavBarChild,
    FooterComponent,
  },
};
</script>

<style scoped>
#layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
