<!-- YourPageComponent.vue -->
<template>
  <LayoutHomeVue>
    <div id="grid-container">
      <section id="section-carousel">
        <slider-main-component></slider-main-component>
      </section>
      <section class="section-one section-eightpercent">
        <intro-clinic></intro-clinic>
      </section>
      <section class="section-two section-eightpercent">
        <h1>Departments</h1>
        <flip-card-component
          header-title="Dental Implants"
          header-subtitle="Dental implants are artificial tooth roots placed into the jawbone to support replacement teeth or bridges. They provide a durable and functional solution for missing teeth, enhancing both aesthetics and oral health."
          header-img="flip-card/dental-implant.svg"
        ></flip-card-component>
        <flip-card-component
          header-title="Prosthetic Dentistry"
          header-subtitle="Prosthetic dentistry involves the design, fabrication, and fitting of dental prostheses, such as crowns, bridges, and dentures. This specialty aims to restore the function and appearance of damaged or missing teeth, improving the overall quality of life for patients."
          header-img="flip-card/prosthetic.svg"
        ></flip-card-component>
        <flip-card-component
          header-title="Aesthetics"
          header-subtitle="Aesthetic dentistry includes various procedures aimed at enhancing the appearance of teeth, gums, and smiles. Treatments such as teeth whitening, veneers, and cosmetic bonding focus on improving aesthetic appeal while preserving oral health."
          header-img="flip-card/aesthetic.svg"
        ></flip-card-component>
        <flip-card-component
          header-title="Orthodontics"
          header-subtitle="Orthodontics is focused on diagnosing, preventing, and treating dental and facial irregularities. This specialty utilizes braces, aligners, and other appliances to correct misaligned teeth and jaws, enhancing both function and aesthetics."
          header-img="flip-card/orthodontics.svg"
        ></flip-card-component>
      </section>
      <section class="section-three section-eightpercent">
        <image-holder-component-two
          :image-url="require('@/assets/img/albania/Untitled-4.webp')"
          :alt-text="'Description of the image'"
          :header-text-zero="'Your New Smile Awaits in Tirana.'"
          :header-text-one="'World-Class Dental Care'"
          :header-text-two="'Unforgettable Experience'"
          :sub-text="'Discover how easy it is to combine affordable, world-class dental treatments with an unforgettable stay in Tirana. Explore our dental services, travel tips, and city highlights.'"
          :is-button="true"
          redirectTo="/dental-tourism"
          overlayRight="-26%"
          overlayBottom="23%"
        />
      </section>
      <section class="section-four">
        <transport-component></transport-component>
      </section>
      <section class="section-five section-eightpercent">
        <div class="left-side">
          <faq-component
            info-card="yes"
            faq-header="Frequently Asked Questions"
          ></faq-component>
        </div>
        <div class="right-side">
          <form-appointment></form-appointment>
        </div>
      </section>
    </div>
  </LayoutHomeVue>
</template>

<script>
import LayoutHomeVue from "@/layouts/LayoutHome.vue";
import SliderMainComponent from "@/components/SliderMainComponent.vue";
import FaqComponent from "@/components/FaqComponent.vue";
import FormAppointment from "@/components/FormAppointment.vue";
import IntroClinic from "@/components/IntroClinic.vue";
import TransportComponent from "@/components/TransportComponent.vue";
import FlipCardComponent from "@/components/FlipCardComponent.vue";
import ImageHolderComponentTwo from "@/components/ImageHolderComponentTwo.vue";

export default {
  components: {
    LayoutHomeVue,
    SliderMainComponent,
    FaqComponent,
    FormAppointment,
    IntroClinic,
    TransportComponent,
    FlipCardComponent,
    ImageHolderComponentTwo,
  },
  data() {
    return {
      faqS: [
        {
          id: 0,
          question:
            "What information do I need to provide for booking an appointment?",
          answer:
            "We only need your full name and your phone number. Our patient care team will call you to book an appointment at the time of your convenience. Alternatively, you can set the appointment time yourself when you book an appointment with us through our website.",
        },
        {
          id: 1,
          question:
            "I feel nervous when I am alone. Can I bring someone along with me?",
          answer:
            "Yes, why not? We want you to feel relaxed and at home during your treatment at Health Medical Center. Although we are certain that you will immediately feel relaxed as soon as you step into our office, you can bring along your loved ones or friends with you for moral support.",
        },
        {
          id: 2,
          question: "How early should I arrive for my appointment?",
          answer:
            "It is up to you how soon you want to come for your appointment. If you want to save your time, you can come right on time for your appointment. However, we recommend that you come slightly early so that you can enjoy a cup of tea or coffee with us, relax at one of our massage chairs, or simply enjoy the serene environment and soothing music in our waiting area.",
        },

        {
          id: 3,
          question: "What if I need to change my appointment date or time?",
          answer:
            "Although we don’t recommend changing your date or time of appointment at the last hour, we understand that emergencies may arise at any time. However, we request that you please inform us as soon as possible if you cannot arrive for your appointment. Please call us at +355 69 20 96 720 and we will be happy to reschedule your appointment.",
        },
        {
          id: 4,
          question: "What will happen during my first checkup appointment?",
          answer:
            "When you first visit us at Health Medical Center, we will make sure that you have a relaxing and enjoyable experience. In addition to a thorough examination of your oral health by our dentists, you will also get a free large x-ray which shows all the teeth and important structures of your mouth (the OPG x-ray). Not only this, you are also entitled to a free smile design during your first visit, and you will have free access to our exclusive spa services at Health Medical Center.",
        },
      ],
    };
  },
};
</script>

<style>
#grid-container {
  max-width: 100%;
  row-gap: 50px;
  display: grid;
}

#section-carousel {
  max-height: 600px;
  height: 600px;
}
#section-carousel-albania {
  max-height: 600px;
  height: 600px;
}

.section-one {
  background-color: #f8f9fa;
  overflow: hidden;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
}

.section-two {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 100%;
  width: 80%;
  justify-items: center;
  background-color: #f8f9fa;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 9px;
  column-gap: 50px;
  padding-left: 34px;
  padding-right: 34px;
}

.section-two h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 700;
  grid-column: 1/ 5;
}

.section-eightpercent .divide-two .left-side {
  padding-right: 100px;
}
.section-eightpercent .divide-two .right-side div {
  height: 100%;
}
.section-eightpercent .divide-two .right-side div img {
  max-width: 100%;
  width: 100%;
}

.section-three {
  max-width: 100%;
  margin: auto;
  width: 80%;
}

.container-general-info {
  display: flex;
  max-width: 70%;
  margin: auto;
  justify-content: space-between;
  padding: 50px 0;
}
.container-general-info > .box {
  width: 244px;
  height: 244px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  display: grid;
  justify-items: center;
  align-items: center;
}
.container-general-info > .box:hover {
  border: 1.4px solid #c49754;
}
.container-general-info > .box > div:nth-child(2) {
  font-size: 3rem;
}

.fa-custom-clinic {
  font-size: 50px;
  color: #c49754;
}

.section-five {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 44px;
}

.container-general-info > .box > div:nth-child(2),
.container-general-info > .box > div:nth-child(3) {
  color: #c49754;
}

@media all and (max-width: 1200px) {
  .section-one > .divide-two {
    grid-template-columns: repeat(1, 1fr);
    max-width: 80%;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .container-general-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 80%;
    row-gap: 50px;
    column-gap: 50px;
  }

  .container-general-info > .box {
    width: 100%;
  }

  .section-five {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 50px;
  }

  .section-one.section-eightpercent {
    max-width: 100%;
  }

  .section-two {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    width: 92%;
  }

  .section-two h1 {
    grid-column: 1 / 3;
  }

  .section-three,
  .section-five {
    width: 92%;
  }

  .section-eightpercent {
    max-width: 92%;
  }
}

@media all and (max-width: 768px) {
  #section-carousel {
    height: auto;
  }

  .section-five {
    margin-top: 0;
  }

  .section-two h1 {
    grid-column: 1 / 2;
    font-size: 2rem;
    line-height: 1;
  }

  .section-eightpercent {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
