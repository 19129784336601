import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router.js"; // In case the file is named index.js inside the folder.

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons"; // Import the icons you want to use
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@fortawesome/fontawesome-free/css/all.css";
import "../src/assets/styles/fonts.css";
import "../src/assets/styles/main.css";
import "../src/assets/styles/global.css";

library.add(faUserSecret);

const app = createApp(App);
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
