<template>
  <div class="gallery">
    <div class="gallery-grid">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="gallery-item"
        @click="openFullscreen(image)"
      >
        <img :src="image.src" :alt="image.alt" />
        <div class="explanation">
          <p>{{ image.explanation }}</p>
        </div>
        <div class="image-header">
          <h3>{{ image.header }}</h3>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="fullscreenImage"
        class="fullscreen-view"
        @click="closeFullscreen"
      >
        <button
          class="close-button"
          @click="closeFullscreen"
          aria-label="Close fullscreen view"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        <img :src="fullscreenImage.src" :alt="fullscreenImage.alt" />
        <h3 class="fullscreen-header">{{ fullscreenImage.header }}</h3>
        <p class="fullscreen-explanation">{{ fullscreenImage.explanation }}</p>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from "vue";

const images = [
  {
    src: require("../assets/img/albania/skanderbegSquare.webp"),
    alt: "Skanderbeg Square",
    header: "Skanderbeg Square (Sheshi Skënderbej):",
    explanation:
      "The heart of Tirana, this expansive square is a must-visit, surrounded by important landmarks such as the National History Museum, the Opera House, and the Ethem Bey Mosque.",
  },
  {
    src: require("../assets/img/albania/bunkArt.webp"),
    alt: "Skanderbeg Square",
    header: "Bunk’Art 1 & 2",
    explanation:
      "These former Cold War bunkers have been turned into museums showcasing Albania’s history from WWII to the communist regime. Visitors can explore art installations and historical exhibitions.",
  },
  {
    src: require("../assets/img/albania/dajti.webp"),
    alt: "Skanderbeg Square",
    header: "Mount Dajti National Park",
    explanation:
      "Offering stunning views of Tirana, visitors can take the Dajti Ekspres cable car to the top, where hiking trails, restaurants, and picnic areas await.",
  },
  {
    src: require("../assets/img/albania/grandParkThree.webp"),
    alt: "Skanderbeg Square",
    header: "Tirana Grand Park (Parku i Madh)",
    explanation:
      "A green oasis in the city, perfect for a relaxing day out, featuring an artificial lake, the Presidential Palace, and the Saint Procopius Church.",
  },
  {
    src: require("../assets/img/albania/pyramid.webp"),
    alt: "Skanderbeg Square",
    header: "Pyramid of Tirana",
    explanation:
      "A controversial monument from the communist era, the pyramid is an iconic structure in the city and is undergoing renovations to become a cultural hub.",
  },
  {
    src: require("../assets/img/albania/ethemBey.webp"),
    alt: "Skanderbeg Square",
    header: "Et'hem Bey Mosque",
    explanation:
      "This is the heart of Tirana and the main public square, named after the national hero Gjergj Kastrioti Skanderbeg. The square is surrounded by important landmarks, such as the National History Museum, Et'hem Bey Mosque, and the Palace of Culture.",
  },
  {
    src: require("../assets/img/albania/nationalMuseum.webp"),
    alt: "Skanderbeg Square",
    header: "National History Museum",
    explanation:
      "Located in Skanderbeg Square, this museum provides an insightful journey through Albania’s rich history, from ancient Illyria to modern times.",
  },
  {
    src: require("../assets/img/albania/houseLeaves.webp"),
    alt: "Skanderbeg Square",
    header: "House of Leaves (Museum of Secret Surveillance)",
    explanation:
      "A museum dedicated to Albania's history of secret surveillance during the communist regime, providing a sobering look into the country's past.",
  },
  {
    src: require("../assets/img/albania/newBazaar.webp"),
    alt: "Skanderbeg Square",
    header: "New Bazaar (Pazari i Ri)",
    explanation:
      "A lively market area where visitors can experience local culture, shop for fresh produce, and enjoy traditional Albanian cuisine.",
  },
];

const fullscreenImage = ref(null);

const openFullscreen = (image) => {
  fullscreenImage.value = image;
};

const closeFullscreen = () => {
  fullscreenImage.value = null;
};
</script>

<style scoped>
.gallery {
  margin: 0 auto;
  padding: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

.explanation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
}

.gallery-item:hover .explanation {
  opacity: 1;
}

.image-header {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
}

.image-header h3 {
  margin: 0;
  font-size: 1rem;
}

.fullscreen-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.fullscreen-view img {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.fullscreen-header {
  color: white;
  margin-top: 10px;
  font-size: 1.5rem;
}

.fullscreen-explanation {
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  max-width: 80%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .fullscreen-header {
    font-size: 1.2rem;
  }

  .fullscreen-explanation {
    font-size: 0.9rem;
  }
}
</style>
