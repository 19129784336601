<template>
  <div id="faq-segment">
    <info-card v-if="infoCard == 'yes'"></info-card>

    <h3 v-if="faqHeader">{{ faqHeader }}</h3>

    <section class="accordion" v-for="f in faqS" :key="f.id">
      <div>
        <div class="accordion-item">
          <button class="accordion-header">
            {{ f.question }}
          </button>
          <div class="accordion-content">
            <p v-html="f.answer"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InfoCard from "@/components/InfoCard.vue";

export default {
  components: { InfoCard },
  props: {
    infoCard: {
      type: String,
      default: null,
    },
    faqHeader: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      faqS: [
        {
          id: 0,
          question:
            "What information do I need to provide for booking an appointment?",
          answer:
            "We only need your full name and your phone number. Our patient care team will call you to book an appointment at the time of your convenience. Alternatively, you can set the appointment time yourself when you book an appointment with us through our website.",
        },
        {
          id: 1,
          question:
            "I feel nervous when I am alone. Can I bring someone along with me?",
          answer:
            "Yes, why not? We want you to feel relaxed and at home during your treatment at Health Medical Center. Although we are certain that you will immediately feel relaxed as soon as you step into our office, you can bring along your loved ones or friends with you for moral support.",
        },
        {
          id: 2,
          question: "How early should I arrive for my appointment?",
          answer:
            "It is up to you how soon you want to come for your appointment. If you want to save your time, you can come right on time for your appointment. However, we recommend that you come slightly early so that you can enjoy a cup of tea or coffee with us, relax at one of our massage chairs, or simply enjoy the serene environment and soothing music in our waiting area.",
        },
        {
          id: 3,
          question: "What if I need to change my appointment date or time?",
          answer:
            "Although we don’t recommend changing your date or time of appointment at the last hour, we understand that emergencies may arise at any time. However, we request that you please inform us as soon as possible if you cannot arrive for your appointment. Please call us at +355 69 20 96 720 and we will be happy to reschedule your appointment.",
        },
        {
          id: 4,
          question:
            "How long does it take to complete a dental implant procedure?",
          answer:
            "The process typically takes a few months, with the first phase being the implant placement and the healing phase following. The final restoration is placed after the implant integrates with the bone.",
        },
        {
          id: 5,
          question: "Can I combine multiple dental treatments in one visit?",
          answer:
            "Yes, depending on the treatments and your specific case, we can often combine procedures to minimize your trips and optimize your time.",
        },
        {
          id: 6,
          question: "Is sedation available for anxious patients?",
          answer:
            "Yes, we offer sedation options for patients who feel anxious during dental procedures to ensure a comfortable and stress-free experience.",
        },
        {
          id: 7,
          question:
            "Do I need a visa to travel to Albania for dental treatment?",
          answer:
            "Visa requirements depend on your country of origin. Many visitors from Europe do not require a visa for short stays. Please check the latest requirements or contact us for assistance.",
        },
        {
          id: 8,
          question:
            "How far is the clinic from the airport, and do you offer transportation?",
          answer:
            "Our clinic is conveniently located 17 km from the airport, and we provide free transportation to and from the airport for our international patients.",
        },
        {
          id: 9,
          question: "Can I get a consultation before arriving?",
          answer:
            "Yes, we offer free virtual consultations for international patients to discuss your dental needs and provide a preliminary treatment plan before you travel. Once you arrive at the clinic, we provide a complimentary in-person consultation along with any necessary 3D scans or X-rays, ensuring a comprehensive assessment at no extra cost.",
        },
        {
          id: 10,
          question: "What aftercare do you provide after my procedure?",
          answer:
            "We provide detailed post-treatment instructions, and our team is always available for follow-up consultations, either in person or online, to ensure your recovery goes smoothly.",
        },
        {
          id: 11,
          question: "Will I need to return for follow-up visits?",
          answer:
            "For most treatments, a follow-up can be done virtually. However, for some procedures like implants, or Clear Aligners we may recommend an in-person follow-up to ensure optimal results.",
        },
      ],
    };
  },
  computed: {
    faqs() {
      return this.faqItems && this.faqItems.length > 0
        ? this.faqItems
        : this.defFaqs;
    },
  },
  mounted() {
    const accordionHeaders = this.$el.querySelectorAll(".accordion-header");

    accordionHeaders.forEach((header) => {
      header.addEventListener("click", function () {
        const isActive = this.classList.contains("active");

        accordionHeaders.forEach((hdr) => {
          if (hdr !== this) {
            hdr.classList.remove("active");
            hdr.parentElement.classList.remove("hoverBl");
            hdr.nextElementSibling.style.maxHeight = null;
          }
        });

        const activeItem = this.nextElementSibling;

        if (isActive) {
          this.classList.remove("active");
          this.parentElement.classList.remove("hoverBl");
          activeItem.style.maxHeight = null;
        } else {
          this.classList.add("active");
          this.parentElement.classList.add("hoverBl");
          activeItem.style.maxHeight = activeItem.scrollHeight + "px";
        }
      });
    });
  },
};
</script>

<style scoped>
.accordion h3 {
  color: #6c63ff;
  font-size: 18px;
  margin-bottom: 10px;
}

.accordion-item {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  font-family: Gilroy !important;
}

.accordion-item:first-of-type {
  border-top: 1px solid #e5e5e5;
}
.accordion-header {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: Gilroy;
  font-weight: 400;
  border: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header:after {
  content: "+";
  font-size: 18px;
  color: #c49754;
}

.accordion-header.active:after {
  content: "-";
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #c49754;
}
.accordion-content > p {
  padding: 20px;
  line-height: 2;
}

.hoverBl {
  border: 1.4px solid #c49754 !important;
}

#faq-segment > h3 {
  color: #c49754;
  margin-bottom: 10px;
  font-size: 24px;
}
</style>
