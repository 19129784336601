<template>
  <div class="container-car">
    <div class="content">
      <h2>From Arrival to Departure,</h2>
      <h2>We’ve Got You Covered.</h2>
      <p>
        Enjoy stress-free travel with our complimentary luxury transportation
        service. From the moment you land to the day you leave, we ensure your
        transfers to and from our clinic are
        <strong>smooth, comfortable, and worry-free.</strong>
      </p>
      <button @click="$router.push({ path: '/contact' })">Contact Us</button>
    </div>

    <img src="../assets/img/transport/car.webp" alt="" id="scrollImage" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      lastScrollPosition: 0,
      scrollListenerAdded: false,
    };
  },
  mounted() {
    this.updateScrollListener();
    window.addEventListener("resize", this.updateScrollListener);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScrollListener);
    if (this.scrollListenerAdded) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    updateScrollListener() {
      if (window.innerWidth > 1200) {
        if (!this.scrollListenerAdded) {
          window.addEventListener("scroll", this.handleScroll);
          this.scrollListenerAdded = true;
        }
      } else {
        if (this.scrollListenerAdded) {
          window.removeEventListener("scroll", this.handleScroll);
          this.scrollListenerAdded = false;
        }
      }
    },
    handleScroll() {
      // Check viewport width
      if (window.innerWidth <= 1200) {
        return; // Exit if the viewport is less than or equal to 1200px
      }

      // Get the current scroll position
      let currentScrollPosition = window.scrollY;

      // Reference the image element
      const image = document.getElementById("scrollImage");

      // Get the current 'right' position of the image as an integer
      let currentRightPosition = parseInt(
        window.getComputedStyle(image).right,
        10
      );

      // Check if the user is scrolling up or down
      if (currentScrollPosition > this.lastScrollPosition) {
        // Scrolling down - Move the image to the right
        if (currentScrollPosition >= 2200 && currentRightPosition <= 444) {
          image.style.right =
            currentRightPosition + currentScrollPosition / 7 + "px";
        }
      } else {
        // Scrolling up - Move the image to the left but don't let it go beyond 0
        let newRightPosition = currentRightPosition - currentScrollPosition / 7;
        if (newRightPosition < 0) {
          newRightPosition = 0; // Prevent the right position from going below 0
        }
        image.style.right = newRightPosition + "px";
      }

      // Update the last scroll position
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>

<style scoped>
.container-car {
  background-image: url("../assets/img/transport/background.webp");
  height: 500px;
  position: relative;
  color: #fff;
}

.content {
  position: absolute;
  bottom: 150px;
  left: 10%;
}

h2 {
  font-size: 38px;
  font-weight: 700;
}

button {
  background: #c49754;
  border: none;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 40px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  margin-top: 20px;
}
button:hover {
  background: #1c1c1c;
  border: #1c1c1c 1px solid;
}

p {
  max-width: 40%;
  margin-top: 20px;
  font-size: 20px;
}

img {
  position: absolute;
  bottom: 0;
  right: 0;
  transition: right 0.5s ease-in-out;
}

@media all and (max-width: 1200px) {
  .content {
    bottom: 30%;
    left: 4%;
  }

  p {
    max-width: 100%;
  }

  img {
    max-width: 70%;
  }
}

@media all and (max-width: 768px) {
  h2 {
    font-size: 22px;
  }

  p {
    font-size: 15px;
  }

  .content {
    bottom: 43%;
    left: 4%;
  }

  img {
    max-width: 90%;
    right: 4%;
  }

  .container-car {
    height: 444px;
  }

  .content {
    bottom: 38%;
    left: 4%;
  }
}
</style>
