<template>
  <div class="scene">
    <div class="card">
      <div class="card__face card__face--front">
        <div class="content">
          <img :src="require(`@/assets/img/${headerImg}`)" />
          <h2>{{ headerTitle }}</h2>
        </div>
      </div>
      <div class="card__face card__face--back">
        <div class="content">
          <h2>{{ headerTitle }}</h2>
          <p>{{ headerSubtitle }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["header-title", "header-img", "header-subtitle"],
};
</script>
<style scoped>
.scene {
  max-width: 100%;
  width: 100%;
}

.card {
  height: 300px;
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}

h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 700;
  margin-top: 20px;
}

p {
  color: rgba(255, 255, 255, 0.8);
}

img {
  height: 120px;
  display: block;
  margin: auto;
  border-radius: 10px;
}

.scene:hover .card {
  transform: rotateY(180deg);
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card__face--front {
  color: white;
}

.card__face--back {
  background: linear-gradient(45deg, #f39c12 0%, #444444 100%);
  color: #333;
  transform: rotateY(180deg);
}

.card__face--back h2 {
  color: #fff;
}

.content {
  text-align: center;
  padding: 20px;
}

p {
  margin: 0;
  line-height: 1.2;
  font-size: 16px;
}

@media all and (max-width: 768px) {
  .card {
    height: 244px;
  }

  .content > img {
    height: 80px;
  }
  .content > h2 {
    font-size: 1.24rem;
    margin-bottom: 0;
  }
}
</style>
