<template>
  <div id="app">
    <header>
      <nav-bar></nav-bar>
    </header>

    <main>
      <router-view />
    </main>

    <footer>
      <FooterComponent />
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* GLOBALS */

* {
  margin: 0;
}
body {
  font-family: Gilroy;
}

.section-eightpercent {
  max-width: 80%;
  margin: auto;
}

.divide-two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.sub-header {
  font-size: 2.2rem;
}

.sub-text {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.18px;
  margin-top: 20px;
}

.color-gray {
  color: #6d7a8c;
}

.list-mother {
  font-size: 18px;
}
.list-mother li {
  margin-top: 20px;
}

.container-page {
  display: grid;
  grid-template-columns: 75% 25%;
  max-width: 80%;
  margin: auto;
}

.container-page > .left-side {
  padding-right: 100px;
}

.general-page-header {
  color: #2d2c54;
  font-size: 1.7em;
  font-style: italic;
  font-weight: 700;
}
.general-page-p {
  color: #6d7a8c;
  margin: 20px 0;
  line-height: 1.8;
}

.container-page {
  margin-top: 50px;
}

.general-page-p > strong {
  font-weight: 700;
  color: #000;
}

.general-page-p-quote {
  color: #2d2c54;
  font-size: 1.7em;
  font-style: italic;
  font-weight: 700;
  text-align: center;
  margin: 50px 0;
}

.faq-ul {
  list-style-type: none;
}
.faq-ul li {
  margin: 20px 0;
}
.faq-ul li::before {
  content: "– "; /* Adds a custom bullet (a dash in this case) */
  color: #333; /* Matches the text color */
  margin-right: 8px; /* Adds space between the dash and the text */
}

@media all and (max-width: 1200px) {
  .container-page {
    grid-template-columns: repeat(1, 1fr);
  }

  .container-page > .left-side {
    padding-right: 0;
  }

  .social-icons {
    justify-content: center;
  }

  .text-content h1 {
    font-size: 30px;
  }

  .text-content p {
    font-size: 16px;
  }
}

/* GLOBALS END */
</style>
