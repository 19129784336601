<template>
  <div v-if="loading" class="loading-screen">
    <div>
      <img src="../assets/img/Logo/logoHMC.svg" alt="" />
      <h3>Loading...</h3>
    </div>
  </div>

  <nav-bar-child></nav-bar-child>
  <nav-bar></nav-bar>

  <!-- Banner Section -->
  <div class="banner-container">
    <div class="banner-content">
      <h1 class="banner-header">Gallery</h1>
      <p class="banner-subtext">
        Explore our clinic's serene environment, state-of-the-art facilities,
        and the compassionate care we provide to each of our patients.
      </p>
    </div>
  </div>

  <!-- Gallery Section -->
  <div class="gallery-container">
    <img
      v-for="(img, index) in images"
      :key="index"
      :src="img"
      alt=""
      @click="openGallery(index)"
      class="gallery-image"
      @load="imageLoaded"
    />
  </div>

  <!-- Modal for displaying enlarged images with navigation -->
  <div v-if="isModalOpen" class="modal" @click="closeGallery">
    <span class="close-button" @click.stop="closeGallery">&times;</span>
    <span class="arrow left-arrow" @click.stop="prevImage">&#10094;</span>
    <img
      :src="images[currentImageIndex]"
      alt="Enlarged Image"
      class="modal-image"
    />
    <span class="arrow right-arrow" @click.stop="nextImage">&#10095;</span>
  </div>

  <footer-component></footer-component>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import NavBarChild from "@/components/NavBarChild.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { ref } from "vue";

const images = ref([
  require("@/assets/img/gallery/1.webp"),
  require("@/assets/img/gallery/2.webp"),
  require("@/assets/img/gallery/3.webp"),
  require("@/assets/img/gallery/4.webp"),
  require("@/assets/img/gallery/5.webp"),
  require("@/assets/img/gallery/6.webp"),
  require("@/assets/img/gallery/7.webp"),
  require("@/assets/img/gallery/8.webp"),
  require("@/assets/img/gallery/9.webp"),
  require("@/assets/img/gallery/10.webp"),
  require("@/assets/img/gallery/11.webp"),
  require("@/assets/img/gallery/12.webp"),
  require("@/assets/img/gallery/13.webp"),
  require("@/assets/img/gallery/14.webp"),
  require("@/assets/img/gallery/15.webp"),
  require("@/assets/img/gallery/16.webp"),
  require("@/assets/img/gallery/17.webp"),
  require("@/assets/img/gallery/18.webp"),
  require("@/assets/img/gallery/19.webp"),
  require("@/assets/img/gallery/20.webp"),
  require("@/assets/img/gallery/21.webp"),
]);

const isModalOpen = ref(false);
const currentImageIndex = ref(null);
const loading = ref(true);
let loadedImagesCount = 0;

const openGallery = (index) => {
  currentImageIndex.value = index;
  isModalOpen.value = true;
};

const closeGallery = () => {
  isModalOpen.value = false;
  currentImageIndex.value = null;
};

const prevImage = () => {
  currentImageIndex.value =
    (currentImageIndex.value - 1 + images.value.length) % images.value.length;
};

const nextImage = () => {
  currentImageIndex.value = (currentImageIndex.value + 1) % images.value.length;
};

const imageLoaded = () => {
  loadedImagesCount++;
  if (loadedImagesCount === images.value.length) {
    setTimeout(() => {
      document
        .querySelector(".loading-screen")
        .classList.add("loading-screen-fade");
      setTimeout(() => {
        loading.value = false;
      }, 800); // Time for fade-out transition to complete
    }, 1500);
  }
};
</script>

<style scoped>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  font-size: 1.5rem;
  opacity: 1; /* Initial opacity for fade-in */
  transition: opacity 0.8s ease; /* Smooth transition */
}

.loading-screen-fade {
  opacity: 0; /* Fade-out effect */
}

.loading-screen > div {
  width: 300px;
  text-align: center;
  color: #000;
}

.loading-screen > div img {
  display: block;
  max-width: 100%;
  margin: auto;
  animation: pulse 1.5s infinite; /* Add pulsing animation */
}

/* Add keyframe for a pulsing effect */
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

/* General Image Styling */
img {
  border-radius: 5px;
}

/* Gallery Container */
.gallery-container {
  max-width: 60%;
  margin: auto;
  column-count: 4;
  margin-top: 44px;
}

.gallery-image {
  width: 100%;
  margin-bottom: 1em;
  cursor: pointer;
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-image {
  max-width: 80%;
  max-height: 80%;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  color: #fff;
  font-size: 2em;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  font-size: 2em;
  color: white;
  cursor: pointer;
  z-index: 1001;
  transform: translateY(-50%);
  user-select: none;
}

.left-arrow {
  left: 10%;
}

.right-arrow {
  right: 10%;
}

.arrow:hover {
  color: #ddd;
}

.banner-container {
  width: 100%;
  height: 444px;
  background-image: url("../assets/img/gallery/10.webp");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 0 20%;
  box-sizing: border-box;
  background-position-y: 61%;
}

.banner-header {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 700;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
}

.banner-subtext {
  font-size: 1.2rem;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 1.2;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
  max-width: 50%;
}

@media all and (max-width: 1200px) {
  .banner-container {
    padding: 0 10%;
  }

  .banner-subtext {
    max-width: 90%;
  }

  .banner-content {
    max-width: 100%;
  }

  .banner-container {
    height: 275px;
  }

  .banner-header {
    font-size: 2rem;
    max-width: 80%;
    line-height: 1.2;
  }

  .gallery-container {
    max-width: 80%;
    column-count: 3;
  }
}

@media (max-width: 768px) {
  .banner-container {
    height: 300px;
  }

  .banner-header {
    font-size: 2rem;
  }

  .banner-subtext {
    font-size: 1rem;
  }

  .gallery-container {
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .banner-container {
    height: 250px;
  }

  .banner-header {
    font-size: 1.5rem;
  }

  .banner-subtext {
    font-size: 0.9rem;
  }
}
</style>
