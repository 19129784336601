import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import AllOnFourPage from "@/pages/AllOnFourPage.vue";
import AllOnSixPage from "@/pages/AllOnSixPage.vue";
import BoneGraftPage from "@/pages/BoneGraftPage.vue";
import BotoxPage from "@/pages/BotoxPage.vue";
import BracesPage from "@/pages/BracesPage.vue";
import ClearAlignersPage from "@/pages/ClearAlignersPage.vue";
import CompositeFillingPage from "@/pages/CompositeFillingPage.vue";
import DentalVeneersPage from "@/pages/DentalVeneersPage.vue";
import DenturesPage from "@/pages/DenturesPage.vue";
import ExaminationPage from "@/pages/ExaminationPage.vue";
import FillersPage from "@/pages/FillersPage.vue";
import GumTreatmentsPage from "@/pages/GumTreatmentsPage.vue";
import HollywoodSmilePage from "@/pages/HollywoodSmilePage.vue";
import ImplantSupportedBridgePage from "@/pages/ImplantSupportedBridgePage.vue";
import MesotherapyPage from "@/pages/MesotherapyPage.vue";
import PorcelainMetalCrownsPage from "@/pages/PorcelainMetalCrownsPage.vue";
import RootCanalTreatmentPage from "@/pages/RootCanalTreatmentPage.vue";
import Scan3D from "@/pages/Scan3D.vue";
import SingleImplantPage from "@/pages/SingleImplantPage.vue";
import SinusLiftPage from "@/pages/SinusLiftPage.vue";
import TeethCleaningPage from "@/pages/TeethCleaningPage.vue";
import TeethWhiteningPage from "@/pages/TeethWhiteningPage.vue";
import ToothExtractionPage from "@/pages/ToothExtractionPage.vue";
import ZirconiaCrownsPage from "@/pages/ZirconiaCrownsPage.vue";
import BeforeAndAfterPage from "@/pages/BeforeAndAfterPage.vue";
import WhoWeArePage from "@/pages/WhoWeArePage.vue";
import ContactPage from "@/pages/ContactPage.vue";
import DentalTourismPage from "@/pages/DentalTourismPage.vue";
import GalleryPage from "@/pages/GalleryPage.vue";

const routes = [
  {
    path: "/",
    component: HomePage,
    meta: {
      title: "Home - HMC",
    },
  },
  {
    path: "/all-on-four",
    component: AllOnFourPage,
    meta: {
      title: "All on Four - HMC",
    },
  },
  {
    path: "/all-on-six",
    component: AllOnSixPage,
    meta: {
      title: "All on Six - HMC",
    },
  },
  {
    path: "/bone-graft",
    component: BoneGraftPage,
    meta: {
      title: "Bone Graft - HMC",
    },
  },
  {
    path: "/botox",
    component: BotoxPage,
    meta: {
      title: "Botox - HMC",
    },
  },
  {
    path: "/braces",
    component: BracesPage,
    meta: {
      title: "Braces - HMC",
    },
  },
  {
    path: "/clear-aligners",
    component: ClearAlignersPage,
    meta: {
      title: "Clear Aligners - HMC",
    },
  },
  {
    path: "/composite-filling",
    component: CompositeFillingPage,
    meta: {
      title: "Composite Filling - HMC",
    },
  },
  {
    path: "/dental-veneers",
    component: DentalVeneersPage,
    meta: {
      title: "Dental Veneers - HMC",
    },
  },
  {
    path: "/dentures",
    component: DenturesPage,
    meta: {
      title: "Dentures - HMC",
    },
  },
  {
    path: "/examination",
    component: ExaminationPage,
    meta: {
      title: "Examination - HMC",
    },
  },
  {
    path: "/fillers",
    component: FillersPage,
    meta: {
      title: "Fillers - HMC",
    },
  },
  {
    path: "/gum-treatments",
    component: GumTreatmentsPage,
    meta: {
      title: "Gum Treatments - HMC",
    },
  },
  {
    path: "/hollywood-smile",
    component: HollywoodSmilePage,
    meta: {
      title: "Hollywood Smile - HMC",
    },
  },
  {
    path: "/implant-supported-bridge",
    component: ImplantSupportedBridgePage,
    meta: {
      title: "Implant Supported Bridge - HMC",
    },
  },
  {
    path: "/mesotherapy",
    component: MesotherapyPage,
    meta: {
      title: "Mesotherapy - HMC",
    },
  },
  {
    path: "/porcelain-metal-crowns",
    component: PorcelainMetalCrownsPage,
    meta: {
      title: "Porcelain Metal Crowns - HMC",
    },
  },
  {
    path: "/root-canal-treatment",
    component: RootCanalTreatmentPage,
    meta: {
      title: "Root Canal Treatment - HMC",
    },
  },
  {
    path: "/3d-scan",
    component: Scan3D,
    meta: {
      title: "Scan 3D - HMC",
    },
  },
  {
    path: "/single-implant",
    component: SingleImplantPage,
    meta: {
      title: "Single Implant - HMC",
    },
  },
  {
    path: "/sinus-lift",
    component: SinusLiftPage,
    meta: {
      title: "Sinus Lift - HMC",
    },
  },
  {
    path: "/teeth-cleaning",
    component: TeethCleaningPage,
    meta: {
      title: "Teeth Cleaning - HMC",
    },
  },
  {
    path: "/teeth-whitening",
    component: TeethWhiteningPage,
    meta: {
      title: "Teeth Whitening - HMC",
    },
  },
  {
    path: "/tooth-extraction",
    component: ToothExtractionPage,
    meta: {
      title: "Tooth Extraction - HMC",
    },
  },
  {
    path: "/zirconia-crowns",
    component: ZirconiaCrownsPage,
    meta: {
      title: "Zirconia Crowns - HMC",
    },
  },
  {
    path: "/before-after",
    component: BeforeAndAfterPage,
    meta: {
      title: "Before and After - HMC",
    },
  },
  {
    path: "/who-we-are",
    component: WhoWeArePage,
    meta: {
      title: "Who We Are - HMC",
    },
  },
  {
    path: "/contact",
    component: ContactPage,
    meta: {
      title: "Who We Are - HMC",
    },
  },
  {
    path: "/dental-tourism",
    component: DentalTourismPage,
    meta: {
      title: "Dental Tourism - HMC",
    },
  },
  {
    path: "/gallery",
    component: GalleryPage,
    meta: {
      title: "Gallery - HMC",
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // Always scroll to the top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "HMC";
  next();
});

export default router;
