<template>
  <section class="page-header">
    <div>
      <div class="text-content">
        <h1>{{ headerTitle }}</h1>
        <p>
          {{ headerSubtitle }}
        </p>
      </div>
      <div class="image-content">
        <img :src="require(`@/assets/img/${headerImg}`)" :alt="headerTitle" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["header-title", "header-img", "header-subtitle"],
};
</script>

<style scoped>
.page-header {
  background-color: #452a7a;
  overflow: hidden;
}

.page-header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 80%;
  color: white;
  position: relative;
  margin: auto;
}

.text-content {
  flex: 1;
  max-width: 50%;
}

.text-content h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.text-content p {
  font-size: 1.2rem;
}

.image-content {
  flex: 1;
  text-align: right;
}

.image-content img {
  max-width: 100%;
  height: auto;
  clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
  margin-bottom: -5px;
}

@media all and (max-width: 1200px) {
  .text-content h1 {
    font-size: 30px;
  }

  .text-content p {
    font-size: 16px;
  }

  .page-header > div {
    max-width: 92%;
  }
}

@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    text-align: center;
  }

  .image-content {
    margin-top: 20px;
  }

  .text-content {
    max-width: 100%;
  }
}
</style>
