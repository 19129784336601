<template>
  <nav class="top-nav">
    <div class="container">
      <div class="left-section">
        <a href="tel:+355692096720" class="nav-link">
          <i class="fas fa-phone"></i>
          <span>+355 69 20 96 720</span>
        </a>
        <a href="#" class="nav-link">
          <i class="fas fa-map-marker-alt"></i>
          <span>Get Directions</span>
        </a>
        <div class="nav-link">
          <i class="far fa-clock"></i>
          <span>Mon - Fri 9am - 6pm, Sat 9am - 1pm</span>
        </div>
      </div>
      <div class="right-section">
        <!-- <div class="language-dropdown">
          <button class="dropdown-toggle">
            <i class="fas fa-flag"></i>
            <span>EN</span>
            <i class="fas fa-chevron-down"></i>
          </button>
          <div class="dropdown-menu">
            <a href="#" class="dropdown-item">English</a>
            <a href="#" class="dropdown-item">Türkçe</a>
          </div>
        </div> -->
        <social-media-icons-component></social-media-icons-component>
      </div>
    </div>
  </nav>
</template>

<script>
import SocialMediaIconsComponent from "./SocialMediaIconsComponent.vue";

export default {
  components: {
    SocialMediaIconsComponent,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css");

:root {
  --primary-color: #3b82f6;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-700: #374151;
}

.top-nav {
  font-family: "Gilroy", sans-serif;
  font-weight: 500;
  background: linear-gradient(to right, var(--gray-100), var(--gray-200));
  padding: 0.75rem 1.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.container {
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-section,
.right-section {
  display: flex;
  align-items: center;
}

.nav-link {
  display: flex;
  align-items: center;
  color: var(--gray-700);
  text-decoration: none;
  margin-right: 1.5rem;
  transition: color 0.2s;
}

.nav-link:hover {
  color: var(--primary-color);
}

.nav-link i {
  margin-right: 0.5rem;
  font-size: 1rem;
}

.nav-link span {
  font-size: 0.875rem;
}

.language-dropdown {
  position: relative;
  margin-right: 1.5rem;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: var(--gray-700);
  cursor: pointer;
  transition: color 0.2s;
}

.dropdown-toggle:hover {
  color: var(--primary-color);
}

.dropdown-toggle i {
  margin-right: 0.5rem;
  font-size: 1rem;
}

.dropdown-toggle span {
  font-size: 0.875rem;
  margin-right: 0.25rem;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  width: 12rem;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
}

.language-dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.dropdown-item {
  display: block;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: var(--gray-700);
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: var(--gray-100);
}

.social-icons {
  display: flex;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: white;
  text-decoration: none;
  border-radius: 0.375rem;
  margin-left: 0.75rem;
  transition: transform 0.2s;
}

.social-icon:hover {
  transform: scale(1.1);
}

.social-icon i {
  font-size: 1rem;
}

.facebook {
  background-color: #1877f2;
}
.twitter {
  background-color: #1da1f2;
}
.instagram {
  background-color: #e4405f;
}
.youtube {
  background-color: #ff0000;
}
.whatsapp {
  background-color: #25d366;
}

@media (max-width: 1200px) {
  /* .top-nav {
    display: none;
  } */

  .container {
    max-width: 94%;
  }
}

@media all and (max-width: 768px) {
  .top-nav {
    display: none;
  }
}
</style>
