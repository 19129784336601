<template>
  <nav-bar-child></nav-bar-child>
  <nav-bar></nav-bar>

  <generic-image-holder-component
    header="Who We Are"
    subText="Committed to Excellence in Dental and Aesthetic Care"
  ></generic-image-holder-component>

  <div id="grid-container">
    <our-vision-component></our-vision-component>

    <div class="dental-clinic">
      <div class="clinic-image"></div>
      <div class="clinic-info">
        <h1 class="clinic-name">About Health Medical Center</h1>
        <p class="clinic-description">
          Welcome to Health Medical Center, a leading dental and aesthetic
          clinic based in the heart of Tirana, Albania. We specialize in
          providing high-quality, affordable treatments to patients from around
          the world, combining expert care with a personalized, patient-centered
          approach.
        </p>
        <p class="clinic-description">
          Our clinic offers the latest in dental technology and aesthetics, with
          a team of internationally trained specialists dedicated to delivering
          exceptional results. Whether you’re seeking dental implants, cosmetic
          dentistry, or a full smile makeover, we tailor every treatment to fit
          your individual needs.
        </p>

        <div class="clinic-stats">
          <div class="stat">
            <h3>20+</h3>
            <p>Years Experience</p>
          </div>
          <div class="stat">
            <h3>5</h3>
            <p>Specialist Dentists</p>
          </div>
          <div class="stat">
            <h3>20+</h3>
            <p>Support Staff</p>
          </div>
        </div>
        <p class="clinic-cta">
          Transform your smile with Health Medical Center in Tirana!
          <a href="/contact" class="contact-us"> Contact us today </a>to
          schedule your consultation and take the first step towards a
          healthier, more confident you. We're here to provide expert care and
          personalized treatment to help you achieve the smile you've always
          dreamed of.
        </p>
        <ul class="clinic-features">
          <li>Complete Dental Care</li>
          <li>Cutting-Edge Cosmetic Solutions</li>
          <li>Skilled Dental Professionals</li>
          <li>Affordable Treatment Plans</li>
          <li>Modern Dental Technology</li>
          <li>Personalized Pre- and Post-Treatment Care</li>
        </ul>
      </div>
    </div>

    <div class="what-makes-us-different">
      <h2>What Makes Us Different?</h2>
      <p class="intro">
        At Health Medical Center, we believe that top-tier dental care goes
        beyond clinical excellence. We focus on delivering a comprehensive
        patient experience that prioritizes comfort, ease, and satisfaction at
        every stage of your treatment. Here's how we make it happen:
      </p>
      <div class="card-container">
        <div v-for="(item, index) in differentiators" :key="index" class="card">
          <div
            class="card-image"
            :style="{ backgroundImage: `url(${item.image})` }"
          ></div>
          <div class="card-content">
            <div class="card-header">
              <h3>{{ item.title }}</h3>
            </div>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="what-makes-us-different">
      <h2>Our Range of Specialized Treatments</h2>
      <p class="intro">
        At our clinic, we combine expertise, state-of-the-art technology, and a
        commitment to excellence to offer a comprehensive array of dental and
        aesthetic treatments, including:
      </p>
      <div class="flip-card-container">
        <flip-card-component
          header-title="Dental Implants"
          header-subtitle="Permanent solutions for missing teeth with advanced implant techniques."
          header-img="flip-card/implant.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Cosmetic Dentistry"
          header-subtitle="Smile makeovers through veneers, teeth whitening, and more."
          header-img="flip-card/cosmetic.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Orthodontics"
          header-subtitle="Straighten your teeth with clear aligners or traditional braces."
          header-img="flip-card/orthodontics.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Restorative Dentistry"
          header-subtitle="Crowns, bridges, and full mouth rehabilitations to restore function and beauty."
          header-img="flip-card/restorative.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Periodontal Treatments"
          header-subtitle="Comprehensive gum care and deep cleanings for optimal oral health."
          header-img="flip-card/perio.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Aesthetic Treatments"
          header-subtitle="Including Botox, dermal fillers, and hair restoration to enhance your confidence and appearance."
          header-img="flip-card/aesthetic.jpg"
        ></flip-card-component>
      </div>
      <div class="cta">
        Ready to start your dental journey with us? <br />
        Contact us today for a free consultation and let us help you achieve the
        smile of your dreams.
        <br />
        <button @click="$router.push({ path: '/contact' })">
          Change Your Smile
        </button>
      </div>
    </div>
  </div>
  <footer-component></footer-component>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import NavBarChild from "@/components/NavBarChild.vue";
import OurVisionComponent from "@/components/OurVisionComponent.vue";
import GenericImageHolderComponent from "@/components/GenericImageHolderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import FlipCardComponent from "@/components/FlipCardComponent.vue";

const differentiators = [
  {
    title: "Personalized Treatment Plans:",
    description:
      "We understand that every patient is unique, and so are their dental needs. That’s why our dentists create customized treatment plans tailored specifically to your goals and preferences. By thoroughly evaluating your dental health and discussing your expectations, we ensure that you receive the best possible care designed just for you.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "World-Class Expertise and Technology:",
    description:
      "Our clinic combines the expertise of experienced dentists with state-of-the-art technology. From advanced 3D imaging for precise diagnosis to minimally invasive techniques, we invest in the latest innovations to achieve optimal results and ensure a smooth experience for every patient.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "Comprehensive Support and Follow-Up:",
    description:
      "Your journey with us doesn’t end with the procedure. We provide comprehensive post-treatment support and personalized aftercare plans to make sure your recovery is smooth and comfortable. Our dedicated team is always available to answer questions and guide you through the aftercare process.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "Comfort and Convenience at Every Step:",
    description:
      "From the moment you arrive, we focus on making your experience stress-free. We offer luxury transport services, assistance with travel arrangements, and a warm, welcoming atmosphere at the clinic. Whether it’s helping with travel plans or providing amenities during your visit, we prioritize your comfort at every stage.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
];
</script>

<style scoped>
:root {
  --primary-color: #4a90e2;
  --text-color: #333;
  --text-color-light: #666;
  --background-light: #ffffff;
  --background-dark: #f0f0f0;
}

#grid-container {
  display: grid;
  max-width: 80%;
  margin: auto;
  margin-top: 44px;
}

.contact-us {
  text-decoration: underline;
  color: #c49754;
  font-weight: 600;
}

.dental-clinic {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  background: linear-gradient(
    135deg,
    var(--background-light) 50%,
    var(--background-dark) 50%
  );
  border-radius: 15px !important;
}

.flip-card-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 30px;
  row-gap: 30px;
}

.clinic-image {
  flex: 1;
  background-image: url("../assets/img/office/office_0.webp");
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.cta {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-top: 44px;
}

.cta button {
  background-color: #c49754;
  padding: 5px 30px;
  color: #fff;
  border-radius: 10px;
}
.cta button:hover {
  background-color: #fff;
  border: 1px solid #c49754;
  padding: 5px 30px;
  color: #000;
  border-radius: 10px;
}

.clinic-info {
  flex: 1;
  padding: 2rem;
}

.who-we-are {
  color: var(--text-color-light);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 500;
}

.clinic-name {
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.clinic-description,
.clinic-mission {
  color: var(--text-color-light);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.clinic-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.stat {
  text-align: center;
}

.stat h3 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.stat p {
  color: var(--text-color-light);
}

.clinic-cta {
  font-style: italic;
  color: var(--text-color-light);
  margin-bottom: 1rem;
}

.clinic-features {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.clinic-features li {
  color: var(--text-color-light);
  display: flex;
  align-items: center;
}

.clinic-features li::before {
  content: "✓";
  color: var(--primary-color);
  margin-right: 0.5rem;
}

@media all and (max-width: 1200px) {
  #grid-container {
    max-width: 92%;
  }

  .card-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .flip-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .dental-clinic {
    flex-direction: column;
  }

  .clinic-image {
    height: 300px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  }

  .clinic-info {
    padding: 1rem;
  }

  .clinic-name {
    font-size: 1.5rem;
  }

  .clinic-stats {
    flex-direction: column;
  }

  .stat {
    margin-bottom: 1rem;
  }

  .clinic-features {
    grid-template-columns: 1fr;
  }

  .card-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .flip-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.what-makes-us-different {
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
}

h2 {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
}

.intro {
  font-size: 1.1rem;
  color: #666;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
  line-height: 1.6;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.card {
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-image {
  width: 120px;
  background-size: cover;
  background-position: center;
  /* clip-path: polygon(0 0, 100% 0, 0 100%); */
  background-position-x: 48%;
}

.card-content {
  padding: 1.5rem;
  flex: 1;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  fill: #2c3e50;
}

.card h3 {
  font-size: 1.3rem;
  color: #2c3e50;
  margin: 0;
}

.card p {
  font-size: 1rem;
  color: #34495e;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .what-makes-us-different {
    padding: 1rem;
  }

  h2 {
    font-size: 2rem;
  }

  .intro {
    font-size: 1rem;
  }

  .card-container {
    grid-template-columns: 1fr;
  }

  .card {
    flex-direction: column;
  }

  .card-image {
    width: 100%;
    height: 120px;
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }
}
</style>
