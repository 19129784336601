<!-- layouts/LayoutServices.vue -->
<template>
  <div id="layout-container">
    <!-- Header Section -->
    <header>
      <nav-bar-child></nav-bar-child>
      <nav-bar></nav-bar>
    </header>

    <!-- Main Content -->
    <main>
      <page-header
        :header-title="headerTitle"
        :header-subtitle="headerSubtitle"
        :header-img="headerImg"
      ></page-header>
      <div class="container-page">
        <slot></slot>
        <div class="right-side">
          <sidebar-departments-component></sidebar-departments-component>
          <social-media-boxes></social-media-boxes>
        </div>
      </div>
    </main>

    <!-- Footer Section -->
    <footer>
      <footer-component></footer-component>
    </footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import NavBarChild from "@/components/NavBarChild.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import SidebarDepartmentsComponent from "@/components/SidebarDepartmentsComponent.vue";
import SocialMediaBoxes from "@/components/SocialMediaBoxes.vue";
import PageHeader from "@/components/PageHeader.vue";

export default {
  name: "LayoutServices",
  props: ["headerTitle", "headerImg", "headerSubtitle"],
  components: {
    NavBar,
    NavBarChild,
    PageHeader,
    SidebarDepartmentsComponent,
    SocialMediaBoxes,
    FooterComponent,
  },
};
</script>

<style scoped>
#layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media all and (max-width: 1200px) {
  .container-page {
    max-width: 92%;
  }

  .social-icons {
    margin-top: 45px;
  }
}
</style>
