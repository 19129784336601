<template>
  <div class="carousel">
    <div
      class="carousel-inner"
      :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
    >
      <div v-for="(slide, index) in slides" :key="index" class="carousel-slide">
        <div class="carousel-content">
          <h1 class="carousel-title">{{ slide.title }}</h1>
          <p class="carousel-subtitle">{{ slide.subtitle }}</p>
          <button
            class="carousel-button"
            id="btn-redirect"
            @click="$router.push({ path: '/who-we-are' })"
          >
            Learn More
          </button>
        </div>
        <img :src="slide.image" :alt="slide.alt" />
      </div>
    </div>

    <button
      @click="prevSlide"
      class="carousel-nav-button prev"
      aria-label="Previous slide"
    >
      <ChevronLeftIcon class="h-8 w-8" />
    </button>
    <button
      @click="nextSlide"
      class="carousel-nav-button next"
      aria-label="Next slide"
    >
      <ChevronRightIcon class="h-8 w-8" />
    </button>

    <div class="carousel-dots">
      <button
        v-for="(_, index) in slides"
        :key="index"
        @click="goToSlide(index)"
        :class="{ active: currentIndex === index }"
        :aria-label="`Go to slide ${index + 1}`"
      ></button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-vue-next";

const slides = [
  {
    image: require("@/assets/img/office/office_0.webp"),
    alt: "Slide 1",
    title: "Health Medical Center",
    subtitle: "Transform your smile with us",
  },
  // {
  //   image: require("@/assets/img/sampleCarousel_0.jpg"),
  //   alt: "Slide 2",
  //   title: "Second Slide Title",
  //   subtitle: "This is the Second slide subtext",
  // },
  // {
  //   image: require("@/assets/img/sampleCarousel_1.jpg"),
  //   alt: "Slide 3",
  //   title: "Third Slide Title",
  //   subtitle: "This is the third slide subtext",
  // },
];

const currentIndex = ref(0);
const timer = ref(null);

const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % slides.length;
};

const prevSlide = () => {
  currentIndex.value = (currentIndex.value - 1 + slides.length) % slides.length;
};

const goToSlide = (index) => {
  currentIndex.value = index;
};

const startTimer = () => {
  timer.value = setInterval(nextSlide, 115000);
};

const stopTimer = () => {
  clearInterval(timer.value);
};

onMounted(() => {
  startTimer();
});

onUnmounted(() => {
  stopTimer();
});
</script>

<style scoped>
.carousel {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
}

.carousel-inner {
  display: flex;
  transition: transform 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: 100%;
}

.carousel-slide {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  z-index: 1;
}

.carousel-content {
  z-index: 2;
  text-align: center;
  color: white;
  position: relative;
  max-width: 80%;
  padding: 1rem;
  border-radius: 10px;
}

.carousel-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.carousel-subtitle {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

.carousel-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  background-color: rgba(255, 255, 255, 0.85);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.carousel-button:hover {
  background-color: rgba(255, 255, 255, 1);
  transform: scale(1.05);
}

.carousel-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}
/* 
.carousel-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  z-index: 3;
  padding: 1rem;
} */

.carousel-nav-button:hover,
.carousel-nav-button:focus {
  opacity: 1;
  outline: none;
}

.carousel-nav-button.prev {
  left: 0;
}

.carousel-nav-button.next {
  right: 0;
}

/* .carousel-dots {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
}

.carousel-dots button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: transform 0.15s ease, background-color 0.15s ease;
}

.carousel-dots button.active {
  background-color: white;
  transform: scale(1.2);
} */

@media (max-width: 768px) {
  .carousel {
    height: 250px;
  }

  .carousel-content {
    padding: 0.5rem;
    max-width: 90%;
  }

  .carousel-title {
    font-size: 2rem;
  }

  .carousel-subtitle {
    font-size: 1rem;
  }

  .carousel-button {
    font-size: 0.9rem;
  }

  .carousel-nav-button {
    padding: 0.5rem;
  }

  .carousel-nav-button .h-8 {
    height: 1.5rem;
    width: 1.5rem;
  }
}
</style>
