<template>
  <div id="faq-segment">
    <info-card v-if="infoCard == 'yes'"></info-card>

    <h3 v-if="faqHeader">{{ faqHeader }}</h3>

    <section class="accordion" v-for="f in faqs" :key="f.id">
      <div>
        <div class="accordion-item">
          <button class="accordion-header">
            {{ f.question }}
          </button>
          <div class="accordion-content">
            <p v-html="f.answer"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InfoCard from "@/components/InfoCard.vue";

export default {
  components: { InfoCard },
  props: {
    infoCard: {
      type: String,
      default: null,
    },
    faqHeader: {
      type: String,
      default: null,
    },
    faqItems: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      defFaqs: [
        {
          id: 0,
          question: "Esthetic Dentistry",
          answer: `
        <ul class='faq-ul'>
            <li><router-link to="/teeth-whitening">Teeth Whitening</router-link></li>
            <li><router-link to="/dental-bridges">Dental Bridges</router-link></li>
            <li><router-link to="/digital-smile-design">Digital Smile Design</router-link></li>
            <li><router-link to="/optimizing-gingival-esthetics">Optimizing Gingival Esthetics</router-link></li>
            <li><router-link to="/dental-crowns">Dental Crowns</router-link></li>
            <li><router-link to="/celebrity-smile">Celebrity Smile</router-link></li>
        </ul>`,
        },
        {
          id: 1,
          question: "Orthodontics",
          answer: `
        <ul class='faq-ul'>
            <li><router-link to="/invisible-orthodontics">Invisible Orthodontics</router-link></li>
            <li><router-link to="/preventive-orthodontics-myobrace">Preventive Orthodontics Myobrace</router-link></li>
        </ul>`,
        },
        {
          id: 2,
          question: "Dental Implants",
          answer: `
        <ul class='faq-ul'>
            <li><router-link to="/multiple-teeth-replacement">Multiple Teeth Replacement</router-link></li>
            <li><router-link to="/single-tooth-replacement">Single Tooth Replacement</router-link></li>
            <li><router-link to="/all-on-four">All On Four</router-link></li>
            <li><router-link to="/dental-implant-surgery">Dental Implant Surgery</router-link></li>
        </ul>`,
        },
        {
          id: 3,
          question: "Same Day Dentistry",
          answer: `
        <ul class='faq-ul'>
            <li><router-link to="/same-day-dentistry">Same Day Dentistry</router-link></li>
        </ul>`,
        },
        {
          id: 4,
          question: "Pedodontics",
          answer: `
        <ul class='faq-ul'>
            <li><router-link to="/pedodontics">Pedodontics</router-link></li>
        </ul>`,
        },
        {
          id: 5,
          question: "Dental Laser Treatment",
          answer: `
        <ul class='faq-ul'>
            <li><router-link to="/dental-laser-treatment">Dental Laser Treatment</router-link></li>
        </ul>`,
        },
        {
          id: 6,
          question: "Surgery Dentistry",
          answer: `
        <ul class='faq-ul'>
            <li><router-link to="/facial-cosmetics-with-botox-and-fillers">Facial Cosmetics with Botox and Fillers</router-link></li>
        </ul>`,
        },
      ],
    };
  },
  computed: {
    faqs() {
      return this.faqItems && this.faqItems.length > 0
        ? this.faqItems
        : this.defFaqs;
    },
  },
  mounted() {
    const accordionHeaders = this.$el.querySelectorAll(".accordion-header");

    accordionHeaders.forEach((header) => {
      header.addEventListener("click", function () {
        const activeItem = this.nextElementSibling;

        this.classList.toggle("active");
        this.parentElement.classList.toggle("hoverBl");

        if (activeItem.style.maxHeight) {
          activeItem.style.maxHeight = null;
        } else {
          activeItem.style.maxHeight = activeItem.scrollHeight + "px";
        }
      });
    });
  },
};
</script>

<style scoped>
.accordion h3 {
  color: #6c63ff;
  font-size: 18px;
  margin-bottom: 10px;
}

.accordion-item {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  font-family: Gilroy !important;
}

.accordion-item:first-of-type {
  border-top: 1px solid #e5e5e5;
}
.accordion-header {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: Gilroy;
  font-weight: 400;
  border: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header:after {
  content: "+";
  font-size: 18px;
  color: #c49754;
}

.accordion-header.active:after {
  content: "-";
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #c49754;
}
.accordion-content > p {
  padding: 20px;
  line-height: 2;
}

.hoverBl {
  border: 1.4px solid #c49754 !important;
}

#faq-segment > h3 {
  color: #c49754;
  margin-bottom: 10px;
  font-size: 24px;
}
</style>
